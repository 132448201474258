<template>
  <div class="header-actions-menu" :class="{ 'header-actions-menu--small': isSmall }">
    <ul class="header-actions-menu__list">
      <li
        v-for="(element, index) in elements"
        :key="index"
        class="header-actions-menu__item"
        :class="{ 'header-actions-menu__item--current': element.isCurrent }"
        >
        <img src="https://flagcdn.com/mc.svg" class="header-actions__flag" v-if="element.title === 'Monaco'" />
        <img :src="element.icoName" class="header-actions__flag" v-else />
        <div class="header-actions-menu__link">{{ element.title }}</div>
      </li>
      <li class="header-actions-menu__item" @click="sendDataToParent">
        <div class="change-local">Change your location of delivery</div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'HeaderActionsMenu',
    props: ['elements', 'isSmall'],
    methods: {
        sendDataToParent() {
        this.$emit('data-sent', true );
      }
    },
  }
</script>

<style scoped lang="scss">
img.header-actions__flag {
    height: 30px;
    width: 40px;
    margin: 0 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}
  .header-actions-menu {
    padding: 8px 0;
    width: 243px;
    background-color: #FFFFFF;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;

    &.header-actions-menu--small {
      width: 205px;
    }
  }
  .change-local {
    color: darkblue;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}
  .header-actions-menu__item {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: var(--color-grey-1);
      border-bottom-color: transparent;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-grey-2);
    }
  }

  .header-actions-menu__item--current {
    background-color: var(--color-grey-2);

    .header-actions-menu__link {
      color: var(--color-grey-6);
    }
  }

  .header-actions-menu__link {
    display: block;
    padding: 15px 0 15px;
    box-sizing: border-box;
    text-align: left;
    font-size: .875rem;
    font-weight: bold;
    color: var(--secondary-color-dark);
    text-transform: uppercase;
  }
</style>