<template>
  <div class="header-actions">
    <div v-if="isPortPage && portData" class="header-actions__item header-actions__item--port">
      <button type="button" class="header-actions__button" @click="goToThePort" aria-label="go to port">
        <svg-icon name="actions-port" class="header-actions__ico"></svg-icon>
        <span class="header-actions__text">{{ portData.name }}</span>
      </button>
      <div class="header-actions__dropdown header-actions__dropdown--hoverable">
        <HeaderActionsMenu :elements="menuPortElements"/>
      </div>
    </div>

    <div class="header-actions__item header-actions__item--region">
      <client-only>
        <div class="hover-lang-btn" @mouseover="showLocationNotification" @mouseleave="hideLocationNotification">
          <button type="button" class="header-actions__button" @click="toggleRegionMenu" :aria-label="siteLocation">
            <template>
              <img src="https://flagcdn.com/mc.svg" class="header-actions__flagico" alt="flag" v-if="siteLocation === 'Monaco'" />
              <img :src="siteFlag" class="header-actions__flagico" alt=img v-else />
              <span class="header-actions__text">{{ siteLocation }}</span>
            </template>
            <svg-icon
            name="arrow-down"
            class="header-actions__ico header-actions__ico--arrow-down"
            :class="{ 'header-actions__ico--arrow-up': isRegionMenuOpen }"
            ></svg-icon>
          </button>
          <div class="show-location-text">
            <div class="location-hover-dropdown">
              <ul class="lang-list">
                <li class="dropdown-list"><p class="location-hove-pop" v-if="showNotification"><span class="location-hove-span">Your default delivery location is set to {{ siteLocation }}. If you would like to change your delivery location,</span>  <span class="click_here_text" @click="handleDataFromChild">Click Here.</span></p></li>
              </ul>
            </div>
          </div>
        </div>
      </client-only>
      <div class="header-actions__dropdown" v-if="isRegionMenuOpen" v-on-clickaway="closeRegionMenu">
        <HeaderActionsMenu :elements="menuCountriesElements" :isSmall="true" @data-sent="handleDataFromChild"/>
      </div>
    </div>
    <div class="country-location-popup">
      <CountryLocations v-if="isLocalPopupOpen" @close-popup="closeLocalPopup" @country-selected="handleCountrySelected" />
    </div>

    <div class="header-actions__item header-actions__item--mobile header-actions__item--region">
      <client-only>
        <button type="button" class="header-actions__button mobile_flag" @click="toggleRegionMenu" aria-label="flag">
          <!-- <template v-if="siteRegion === REGIONS.EU">
            <svg-icon name="region-eu" class="header-actions__ico"></svg-icon>
          </template>
          <template v-else-if="siteRegion === REGIONS.US">
            <svg-icon name="region-us" class="header-actions__ico"></svg-icon>
          </template> -->
          <template>
            <img src="https://flagcdn.com/mc.svg" class="header-actions__flagico" alt="flag" v-if="siteLocation === 'MONACO'" />
            <img :src="siteFlag" class="header-actions__flagico" alt="flag" v-else />
          </template>
        </button>
      </client-only>
      <div
        class="header-actions__dropdown header-actions__dropdown--mobile"
        v-if="isRegionMenuOpen"
        v-on-clickaway="closeRegionMenu"
      >
        <HeaderActionsMenu :elements="menuCountriesElements" :isSmall="true" @data-sent="handleDataFromChild"/>
      </div>
    </div>

    <div v-if="$auth.loggedIn" class="header-actions__item header-actions__item--profile">
      <button class="header-actions__button" :aria-label="`HI, ${this.$auth.user.first_name}`">
        <svg-icon name="actions-profile" class="header-actions__ico"></svg-icon>
        <span class="header-actions__text">
          {{ !!this.$auth.user.first_name ? `HI, ${this.$auth.user.first_name}` : 'Profile' }}
        </span>
      </button>
      <div class="header-actions__dropdown header-actions__dropdown--hoverable">
        <HeaderActionsDropdown/>
      </div>
    </div>

    <div v-if="!$auth.loggedIn" class="header-actions__item">
      <button class="header-actions__button" type="button" @click="openLogin" aria-label="login">
        <svg-icon name="actions-login" class="header-actions__ico"></svg-icon>
        <span class="header-actions__text">{{ $t('user-log-in') }}</span>
      </button>
    </div>

    <div class="header-actions__item header-actions__item--cart">
      <button
        class="header-actions__button"
        :class="{ 'header-actions__button--active': productsInCartCount }"
        type="button"
        @click="openCart"
        aria-label="cart"
      >
        <svg-icon name="actions-cart" class="header-actions__ico"></svg-icon>
        <span class="header-actions__text">{{ $t('cart') }}</span>
        <span class="header-actions__counter" v-if="productsInCartCount">{{ productsInCartCount }}</span>
      </button>
    </div>

    <!-- start language change dropdown -->
    <div class="dropdown" @mouseover="onHover" @mouseleave="onLeave">
      <button class="dropdown-btn" aria-label="$-€-₺">
         <p> {{ siteLang }} / <span v-if="siteRegion === REGIONS.US"> $ </span> <span v-if="siteRegion === REGIONS.EU"> € </span><span v-if="siteRegion === REGIONS.LI"> ₺ </span></p>
      </button>
      <div class="lang-dropdown-content" v-if="isLangDropdown">
        <ul class="lang-list">
          <li class="dropdown-list" :class="{ active: siteLang === 'en' }"><button class="lang-btn" @click="changeLanguage('en')">ENGLISH - en</button></li>
          <li class="dropdown-list" :class="{ active: siteLang === 'fr' }"><button class="lang-btn" @click="changeLanguage('fr')">FRENCH - fr</button></li>
          <li class="dropdown-list" :class="{ active: siteLang === 'tr' }"><button class="lang-btn" @click="changeLanguage('tr')">TURKISH - tr</button></li>
          <li class="dropdown-list" :class="{ active: siteLang === 'it' }"><button class="lang-btn" @click="changeLanguage('it')">ITALIAN - it</button></li>
          <li class="dropdown-list" :class="{ active: siteLang === 'es' }"><button class="lang-btn" @click="changeLanguage('es')">SPANISH - es</button></li>
          <li class="dropdown-list" :class="{ active: siteLang === 'el' }"><button class="lang-btn" @click="changeLanguage('el')">GREEK - el</button></li>
        </ul>
        <div class="cur-section">
          <p>CHANGE CURRENCY</p>
          <ul class="cur-list">
            <li  @click="changeCurrency(REGIONS.US)">
              <input type="radio" id="us" name="fav_language" value="US" :checked="siteRegion === REGIONS.US">
              <label for="US">US$-US DOLLAR</label>
            </li>
            <li @click="changeCurrency(REGIONS.EU)">
              <input type="radio" id="eu" name="fav_language" value="EU" :checked="siteRegion === REGIONS.EU">
              <label for="EU">€ EURO</label>
            </li>
            <!-- <li @click="changeCurrency(REGIONS.LI)">
              <input type="radio" id="li" name="fav_language" value="LI" :checked="siteRegion === REGIONS.LI">
              <label for="LI">₺ LIRA</label>
            </li> -->
          </ul>
          <div class="change-cur-lang">
            <p @click="goToChangePage">CLICK TO CHANGE CURRENCY/LANGUAGE</p>
          </div>
        </div>
      </div>
      <svg-icon
        name="arrow-down"
        class="header-actions__ico header-actions__ico--arrow-down"
      ></svg-icon>
    </div>
    <!-- end -->
    <div class="header-actions__item header-actions__item--menu">
      <button class="header-actions__button" type="button" @click="toggleSidebar" aria-label="open side bar">
        <i class="header-actions__menu">&nbsp;</i>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
  import { POPUP_NAMES } from '@/utils/popup-names';
  import { REGIONS } from '@/utils/types/region';
  import HeaderActionsMenu from '@/components/base/header/BaseHeaderActionsMenu';
  import { mixin as clickaway } from 'vue-clickaway';
  import axios from 'axios';
  export default {
    components: {
      HeaderActionsMenu,
      HeaderActionsDropdown:() => import('@/components/base/header/HeaderActionsDropdown'),
      BaseSidebar:() => import('@/components/base/sidebar/BaseSidebar'),
      CountryLocations:() => import('@/components/base/header/CountryLocations.vue')
    },
    mixins: [clickaway],
    data() {
      return {
        currentLanguage: this.$cookies.get('language') ? this.$cookies.get('language') : 'en',
        selectedLang: this.$cookies.get('selectedLang') ? this.$cookies.get('selectedLang') : 'En',
        isSidebarActive: false,
        POPUP_NAMES,
        REGIONS,
        isPortMenuOpen: false,
        isRegionMenuOpen: false,
        isLocalPopupOpen: false,
        selectedCountry:'',
        selectedCountryFlag: '',
        langCur: '',
        showNotification: this.$route.query.new ? this.$route.query.new : false,
        isLangDropdown: false,
      };
    },
    computed: {
      ...mapGetters({
        productsInCartCount: 'cart/productsInCartCount'
      }),
      ...mapState({
        activeModal: state => state.modal.activeModal,
        portData: state => state.port.portData,
        siteRegion: state => state.shared.siteRegion,
        siteLocation: state => state.shared.siteLocation,
        siteFlag: state => state.shared.siteFlag,
        siteLang: state => state.shared.siteLang
      }),
      isPortPage() {
        return this.$route.params['port_slug'] || this.$route.name === 'port-slug-category_slug';
      },
      menuPortElements() {
        return [
          { title: this.portData.name, icoName: 'actions-port', onClickCb: this.goToPortPage, isCurrent: true },
          { title: 'Global site', icoName: 'earth', onClickCb: this.goToGlobalSite, isCurrent: false }
        ]
      },
      menuCountriesElements() {
        return [
        //   {
        //   title: 'Europe',
        //   icoName: 'region-eu',
        //   onClickCb: () => this.selectRegion(REGIONS.EU),
        //   isCurrent: this.siteRegion === REGIONS.EU
        // }, {
        //   title: 'USA',
        //   icoName: 'region-us',
        //   onClickCb: () => this.selectRegion(REGIONS.US),
        //   isCurrent: this.siteRegion === REGIONS.US
        // },
        {
          title: this.siteLocation,
          icoName: this.siteFlag,
          // isCurrent: this.siteRegion === REGIONS.EU
        }
      ]
      }
    },
    methods: {
      ...mapActions({
        loadOrderItems: 'cart/loadOrderItems'
      }),
      ...mapMutations({
        setActiveModal: 'modal/setComponent',
        unsetComponent: 'modal/unsetComponent',
        setSiteRegion: 'shared/setSiteRegion',
        setCurrentLanguage: 'setCurrentLanguage',
        setSiteLocation: 'shared/setSiteLocation',
        setSiteFlag: 'shared/setSiteFlag',
        setSiteLang: 'shared/setSiteLang',
      }),
      handleDataFromChild(data) {
        this.showNotification = false;
        this.isLocalPopupOpen = data;
        this.closeRegionMenu();
      },
      goToChangePage(){
        this.$cookies.set('realPath', this.$route.path)
        this.$router.push('/location-currency');
        this.isLangDropdown = false
      },
      onHover(){
        this.isLangDropdown = true
      },
      onLeave(){
        this.isLangDropdown = false
      },
      closeLocalPopup() {
        this.isLocalPopupOpen = false;
      },
      showLocationNotification(){
        this.showNotification = true;
      },
      hideLocationNotification(){
        this.showNotification = false;
      },
      handleCountrySelected(country, flag) {
        this.$cookies.set('selectedCountry', country);
        if (this.$auth.loggedIn){
          this.$cookies.set('country_preference', true);
        }else{
          this.$cookies.set('country_preference', false);
        }
        this.setSiteLocation(country);
        this.setSiteFlag(flag);
        this.$router.go();
      },
      toggleSidebar() {
        if (this.activeModal === POPUP_NAMES.BASE_SIDEBAR) {
          this.unsetComponent();
        } else {
          this.setActiveModal(POPUP_NAMES.BASE_SIDEBAR);
        }
      },
      changeCurrency(lang){
        if(lang === 'us'){
          this.langCur = '$ - USD - US Dollar'
          this.$cookies.set('currency', 'usd');
        }else if(lang === 'eu'){
          this.langCur = '€ - EURO'
          this.$cookies.set('currency', 'eur');
        }else if(lang === 'li'){
          this.langCur = '₺ - LIRA - Turkish Lira'
        }
        this.$cookies.set('selectedCurrency', this.langCur)
        this.selectRegion(lang)
        // this.$router.go();
      },
      changeLanguage(locale) {
        this.setCurrentLanguage(locale)
        // Store the language using $cookies instead of localStorage
        this.$cookies.set('language', locale);
        this.currentLanguage = this.$cookies.get('language') ? this.$cookies.get('language') : locale;
        if (locale === 'en') {
          this.selectedLang = 'en';
          this.selectedCurrencyLang = 'ENGLISH - EN';
        } else if (locale === 'fr') {
          this.selectedLang = 'fr';
          this.selectedCurrencyLang = 'FRENCH - FR';
        } else if (locale === 'it') {
          this.selectedLang = 'it';
          this.selectedCurrencyLang = 'ITALIAN - IT';
        } else if (locale === 'es') {
          this.selectedLang = 'es';
          this.selectedCurrencyLang = 'SPANISH - ES';
        } else if (locale === 'el') {
          this.selectedLang = 'el';
          this.selectedCurrencyLang = 'GREEK - EL';
        } else if (locale === 'tr') {
          this.selectedLang = 'tr';
          this.selectedCurrencyLang = 'TURKISH - TR';
        }
        this.setSiteLang(this.selectedLang)
        // Store the selectedLang using $cookies instead of localStorage
        this.$cookies.set('selectedLang', this.selectedLang);
        this.$cookies.set('selectedCurrencyLang', this.selectedCurrencyLang)
        // Update the language in the VueI18n instance
        this.$i18n.locale = locale;
        this.$router.go();
      },
      openLogin() {
        this.setActiveModal(POPUP_NAMES.LOGIN_POPUP);
      },
      closePopup() {
        this.unsetComponent();
      },
      openCart() {
        const popupName = this.productsInCartCount ? POPUP_NAMES.SHOPPING_CART_POPUP : POPUP_NAMES.EMPTY_SHOPPING_CART_POPUP;
        this.setActiveModal(popupName);
      },
      goToThePort() {
        this.$router.push({ name: 'port-slug-category_slug',  params: { slug: this.portData.slug } });
      },
      openPortMenu() {
        this.isPortMenuOpen = true;
      },
      closePortMenu() {
        this.isPortMenuOpen = false;
      },
      toggleRegionMenu() {
        document.querySelector('.location-hover-dropdown').classList.add('remove-text')
        this.isRegionMenuOpen = !this.isRegionMenuOpen;
        if(this.isRegionMenuOpen === false){
          document.querySelector('.location-hover-dropdown').classList.remove('remove-text')
        }
      },
      closeRegionMenu() {
        document.querySelector('.location-hover-dropdown').classList.remove('remove-text')
        this.isRegionMenuOpen = false;
      },
      goToPortPage() {
        this.$router.push({ name: 'port-slug-category_slug', params: { slug: this.portData.slug } });
        this.closeRegionMenu();
      },
      goToGlobalSite() {
        this.$router.push({ path: '/' });
        this.closeRegionMenu();
      },
      selectRegion(region) {
        this.setSiteRegion(region);
        this.closeRegionMenu();
      }
    },
  async mounted() {
    const isCrawler = window.navigator.userAgent.match(/googlebot|google\.com\/bot\.html/i);
      this.loadOrderItems();
      if(process && process.client){
        if (!this.$cookies.get('selectedCountry') && !isCrawler && !this.$isGooglebot) {
          const worker = new Worker('/js/headerAPIcall.worker.js');
          let token = '5a03228116783d';
          if(process.env.ENV_NAME === 'production') token = 'd1c6a0fe3f3264';
          if (this.$auth.loggedIn){
            if(!!this.$auth.user.country){
              worker.postMessage({country: this.$auth.user.country, token})
            }
          }else{
            worker.postMessage({country: "Not loggedIn", token});
          }

          worker.onmessage = (event) => {
            const validateCurrentCountry = event.data;
            const hasVisitedBefore = localStorage.getItem('visitedBefore');
            if (!hasVisitedBefore) {
              localStorage.setItem('visitedBefore', 'true');
              this.showNotification = true;
            }
            if (Array.isArray(validateCurrentCountry) && validateCurrentCountry.length) {
              this.selectedCountry = validateCurrentCountry[0]?.name?.common;
              this.selectedCountryFlag = validateCurrentCountry[0].flags.svg;
            } else {
              this.selectedCountry = 'Monaco'
              this.selectedCountryFlag = 'https://flagcdn.com/mc.svg'
            }
            this.$cookies.set('selectedCountry', this.selectedCountry);
            this.setSiteLocation(this.selectedCountry);
            this.setSiteFlag(this.selectedCountryFlag);
          }
        }
      }
      if (this.showNotification) {
      setTimeout(() => {
        this.showNotification = false;
      }, 5000); // Hide after 5 seconds
      }
    },
  };
</script>

<style lang="scss">
.cur-section {
    color: #000000;
    font-weight: bold;
    font-size: .875rem;
    padding: 10px;
    p {
      font-size: 0.675rem;
      margin-bottom: 10px;
    }
    .cur-list{
      li{
        padding: 5px 0;
        label, input {
          cursor: pointer;
        }
      }
    }
    .change-cur-lang{
      margin-top: 8px;
      p{
        text-align: center;
        color: darkblue;
        cursor: pointer;
      }
    }
}
.header-actions__flagico{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 22px;
    height: 22px;
  }
   /* Style for dropdown container */
   .dropdown , .hover-lang-btn{
      position: relative;
      display: inline-block;
    }

    /* Style for dropdown button */
    .dropdown-btn {
      background-color: #22201F;
      color: #ffffff;
      padding: 10px;
      font-size: .875rem;
      border: none;
      cursor: pointer;
      display: flex;
      font-weight: bold;
      text-transform: uppercase;
      align-items: center;
    }
    .location-hover-dropdown {
      position: absolute;
      min-width: 165px;
      top: 30px;
      z-index: 1;
      @media (--viewport-mobile){
        margin-left: -100px;
      }
    }
    /* Style for dropdown content */
    .lang-dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 165px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      @media (--viewport-mobile){
        margin-left: -100px;
      }
    }
    .dropdown-list.active {
      background-color: var(--color-grey-2);
      color: var(--color-grey-6);
    }
    .dropdown-list {
      padding: 10px;
    }
    .lang-btn {
      display: flex;
      color: #000000;
      font-weight: bold;
      font-size: .875rem;
    }
    /* Style for dropdown content options */
    .flagImg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
    }

    /* Show dropdown content when hovering over dropdown button */
    .dropdown:hover .lang-dropdown-content, .hover-lang-btn:hover .location-hover-dropdown {
      display: block;
    }

  .header-actions {
    height: 100%;
    display: flex;

    @media (--viewport-tablet) {
      display: flex;
    }

    @media (--viewport-desktop) {
      margin: 0 -15px;
    }
  }

  .header-actions__item {
    display: none;
    position: relative;

    &.header-actions__item--region {
      .header-actions__text {
        margin-right: 11px;
      }
    }

    &:hover {
      .header-actions__dropdown--hoverable {
        display: block;
      }
    }

    @media (--viewport-tablet) {
      display: block;
      padding: 0 15px;
      box-sizing: border-box;
      & + .header-actions__item {
        &:after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          content: "";
          width: 1px;
          height: 24px;
          background-color: var(--secondary-color-light);
        }
      }
    }
  }

  .header-actions__sidebar {
    display: none;
  }

  .header-actions__sidebar--active {
    display: block;
  }
  .mobile_flag{
      width: 30px !important;
    }

  .header-actions__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    /* width: 40px; */
    width: 100%;
    font-size: .75rem;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 0.01em;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      width: auto;
      padding-left: 33px;
      font-size: .875rem;
    }

    &:hover {
      color: var(--color-grey-4);
    }
  }

  .header-actions__item--menu {
    display: flex;

    .header-actions__button {
      justify-content: center;

      @media (--viewport-tablet) {
        padding-left: 0;
      }
    }

    @media (--viewport-desktop) {
      display: none;
    }
  }

  .header-actions__item--cart {
    display: flex;

    .header-actions__button {
      display: flex;
      padding-right: 0;
    }
  }

  .header-actions__item--port {
    display: none;

    .header-actions__button {
      display: flex;
      padding-right: 0;
    }

    @media (--viewport-desktop) {
      display: flex;
    }
  }

  .header-actions__item--region {
    display: none;

    @media (--viewport-desktop) {
      display: flex;
      padding-right: 25px;
    }
  }

  .header-actions__item--mobile {
    display: flex;
    padding-right: 2px;

    &:after {
      display: none;
    }

    @media (--viewport-desktop) {
      display: none;
    }
  }

  .header-actions__button--active {
    .header-actions__ico {
      fill: var(--brand-color);
    }
    .header-actions__counter {
      display: flex;
    }

    &:hover {
      .header-actions__ico {
        fill: var(--brand-color);
      }
    }
  }

  .header-actions__text {
    display: none;

    @media (--viewport-tablet) {
      display: inline-block;
    }
  }
  .header-actions__ico {
    width: 24px;
    height: 24px;
    fill: #ffffff;
    transition: 0.3s ease;

    &.header-actions__ico--arrow-down {
      display: none;
      position: absolute;
      top: 45%;
      left: 100%;
      width: 10px;
      height: 6px;
      @media (--viewport-tablet) {
        display: block;
        top: 50%;
    }
    }

    &.header-actions__ico--arrow-up {
      transform: translateY(-50%) rotate(180deg);
    }

    @media (--viewport-tablet) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 22px;
      height: 20px;
    }
  }

  .header-actions__menu {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 3px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: inherit;
      height: inherit;
      background-color: inherit;
      border-radius: 3px;
    }

    &:before {
      top: -5px;
    }

    &:after {
      bottom: -7px;
    }
  }

  .header-actions__counter {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: 3px;
    transform: translateY(-50%);
    right: 0;
    border-radius: 50%;
    background-color: var(--brand-color);
    min-width: 22px;
    min-height: 22px;
    color: #ffffff;
    font-size: .75rem;
    letter-spacing: -0.05em;
    font-weight: 600;
    border: 2px solid var(--secondary-color-medium);
    box-sizing: border-box;
    transition: 0.3s ease;

    @media (--viewport-tablet) {
      top: 1px;
      left: 8px;
      right: auto;
      min-width: 24px;
      min-height: 24px;
    }
  }

  .header-actions__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 4px;

    &.header-actions__dropdown--hoverable {
      display: none;
    }
  }

  .header-actions__dropdown--mobile {
    display: block;
  }
  p.location-hove-pop {
    position: absolute;
    top: 20px;
    background: white;
    color: black;
    width: 30em;
    text-align: left;
    height: auto;
    padding: 10px 20px;
    left: 35%;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    text-transform: initial
}

 .click_here_text{
  text-align: center;
    color: #FF6600;
    cursor: pointer;
    border-bottom: 1px solid #FF6600;
 }
 .location-hove-pop::before {
    content: '';
    position: absolute;
    display: block;
    width: 0px;
    left: 15%;
    top: 8%;
    border: 15px solid transparent;
    border-top: 0;
    border-bottom: 15px solid white;
    transform: translate(-50%, calc(-100% - 5px));
}
.remove-text{
  display: none !important;
}
</style>